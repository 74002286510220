<template>
  <v-expansion-panels v-if="col.value === 'title'" v-model="copyItem.expanded" multiple>
    <v-expansion-panel :key="`pl-${item.id}`">
      <v-expansion-panel-header>
        <td>
          <div
            v-if="col.value === 'title'"
            class="d-flex wrap-list-section__header px-4 justify-space-between clickable"
          >
            <div class="d-flex align-center" style="overflow-wrap: anywhere">
              <div class="mr-2 d-flex align-center">
                <v-icon
                  size="30"
                  color="black"
                  v-if="copyItem.expanded && copyItem.expanded.length"
                >
                  mdi-chevron-up
                </v-icon>
                <v-icon v-else size="30" color="black"> mdi-chevron-down</v-icon>
              </div>
              {{ item.title }}
            </div>
            <div class="d-flex align-center">
              <div class="wrap-list-section_header-action-wrap-drag px-4">
                <v-icon size="25"> mdi-menu</v-icon>
              </div>

              <div class="d-flex justify-sm-space-around ml-4">
                <v-btn icon @click.stop="openDialog('delete', index)" :disabled="!canUpdate">
                  <v-icon :title="$t('globals.delete')">mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </td>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <div class="wrap-list-section">
          <section-content
            :section="item"
            @set-valid="setValid"
            :canUpdate="canUpdate"
            type="edit"
          ></section-content>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <dialog-confirmation
      :visible="dialog.delete"
      :title="$t('resources.tab.section.delete.title')"
      :content="$t('resources.tab.section.delete.content')"
      @cancel="closeDialog('delete')"
      @confirm="deleteSection"
    />
  </v-expansion-panels>
</template>

<script>
import DialogConfirmation from '@/views/DialogConfirmation.vue';
import SectionContent from './SectionContent.vue';

export default {
  name: 'WrapListSection',
  components: {
    SectionContent,
    DialogConfirmation,
  },
  watch: {
    item(newVal) {
      this.copyItem = newVal;
    },
    dataItem: {
      handler(newDataItem) {
        this.$emit(
          'set-disabled-draggable',
          newDataItem.items.some((item) => item.expanded?.length >= 1),
        );
      },
      deep: true,
    },
  },
  props: {
    dataItem: {
      type: Object,
    },
    col: { type: Object },
    item: { type: Object },
    index: {
      type: Number,
    },
    canUpdate: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    copyItem: {},
    deletedSection: [],
    expanded: [],
    dialog: {
      section: false,
      delete: false,
    },
    inputDialogSection: '',
  }),
  computed: {
    isLoadingOrUploading() {
      return false;
    },
  },
  methods: {
    closePanel() {
      this.copyItem.expanded = [];
    },
    cancel() {
      this.csvValidationDialog = false;
      this.file = null;
    },
    openDialog(name, item = null) {
      if (name === 'section') {
        this.inputDialogSection = item?.name;
      }
      if (name === 'delete') {
        this.deletedSection = item;
      }
      this.dialog[name] = true;
    },
    closeDialog(name) {
      this.inputDialogSection = '';
      this.dialog[name] = false;
    },
    deleteSection() {
      this.$emit('delete-section', this.index);
      this.closeDialog('delete');
    },
    setValid(valid) {
      this.$emit('set-valid', valid, this.index);
    },
  },
  mounted() {
    this.copyItem = this.item;
  },
};
</script>

<style lang="scss">
@import '~@/styles/core/variables';
@import '~@/styles/core/mixins';

@include admin-layout();

#wrap-list-section {
  padding: 20px;
  min-height: 0;

  .wrap-list-section {
    &__item {
      background: transparent;
      position: relative;
    }

    &__index {
      position: absolute;
      left: -24px;
      transform: translateY(50%);
    }

    &__header {
      width: 100%;
      height: 57px;
      box-shadow: 0 0 8px var(--v-gray-base);
    }

    &__header-action-wrap-drag {
      height: 100%;
      display: flex;
      border-right: 1px solid #7d7d7d;
    }
  }

  // reset css
  .v-expansion-panels {
    .v-expansion-panel {
      .v-expansion-panel {
        &-header {
          padding: 0;
          margin: 0;
          min-height: unset;

          &__icon {
            display: none;
          }
        }
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.add-box-shadow {
  box-shadow: 0 0 8px var(--v-gray-base);
}

.rotate180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.v-data-table > .v-data-table__wrapper tbody tr:first-child:hover td:first-child {
  padding: 0 !important;
}

.formation-table {
  .v-data-table__wrapper {
    table {
      tbody {
        tr {
          td {
            padding: 0 !important;
          }
        }
      }
    }
  }
}
</style>
