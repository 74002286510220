<template>
  <kiosk-configuration
    :isEditing="isEditing"
    configureStandUrl="MyKioskConfigureStand"
    :nameSpaceExhibitor="getNameSpaceExhibitor"
    :nameSpaceEvent="getNameSpaceEvent"
    :formTitle="this.$t('kioskConfigure.edit.title')"
  />
</template>
<script>
import { mapGetters } from 'vuex';

import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { APP_EXHIBITOR_MODULE } from '@/stores/umanize-app/actions/exhibitor/app-exhibitor.actions';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';

import KioskConfiguration from '@/components/kiosk-configuration/KioskConfiguration.vue';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

export default {
  name: 'MyKioskConfigure',
  components: {
    KioskConfiguration,
  },
  data: () => ({}),

  computed: {
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),

    getNameSpaceEvent() {
      return APP_EVENT_MODULE;
    },
    getNameSpaceExhibitor() {
      return APP_EXHIBITOR_MODULE;
    },
    isEditing() {
      return this.$route.path.includes('my-kiosk') ? this.canUpdatePermissions : false;
    },
    canUpdatePermissions() {
      return PermissionsUtil.isAuthorized(
        ['permission.exhibitors.canUpdate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
  },
};
</script>
