<template>
  <v-container class="mx-0 px-1">
    <v-card class="ma-5 px-1" outlined color="transparent">
      <v-card-title> {{ $t(`resources.tab.section.${type}.title`) }} </v-card-title>
      <v-form v-model="valid" ref="form">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                :label="$t('resources.tab.section.title')"
                hide-details="auto"
                :persistent-placeholder="true"
                placeholder="-"
                v-model="sectionLocal.title"
                :rules="rules.title"
                :disabled="!canUpdate"
              />
            </v-col>
          </v-row>

          <v-combobox
            :elevation="0"
            :items="sectionLocal.tags"
            v-model="sectionLocal.tags"
            chips
            clearable
            :label="$t('resources.tab.section.tags')"
            multiple
            :disabled="!canUpdate"
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                @click:close="removeTag(item)"
              >
                <strong>{{ item }}</strong>
              </v-chip>
            </template>
          </v-combobox>

          <h4>{{ $t('resources.tab.section.summary') }}</h4>
          <div>
            <vue-editor-image
              name="summary"
              :content="sectionLocal.summary"
              :disabled="!canUpdate"
              @set-content="setContent"
              @set-has-error-content="setHasErrorContent"
            />
          </div>
          <h4>{{ $t('resources.tab.section.body') }}</h4>

          <div>
            <vue-editor-image
              name="body"
              :content="sectionLocal.body"
              :disabled="!canUpdate"
              @set-content="setContent"
              @set-has-error-content="setHasErrorContent"
            />
          </div>

          <v-col cols="12" sm="12">
            <div>
              <v-btn
                icon
                color="secondary"
                :disabled="!canUpdate"
                @click="toggleValue = !toggleValue"
              >
                <v-icon
                  v-if="toggleValue"
                  size="40"
                  color="primary"
                  :title="$t('resources.tab.section.resources.deactivate')"
                >
                  mdi-toggle-switch
                </v-icon>

                <v-icon size="40" v-else :title="$t('admin.users.list.activate')">
                  mdi-toggle-switch-off
                </v-icon>
              </v-btn>
              {{ $t('resources.tab.section.resources.add') }}
            </div>

            <div v-if="toggleValue">
              <v-btn
                class="mr-3 upload-file my-2"
                color="primary"
                :disabled="!canUpdate"
                @click="documentDialog = true"
              >
                {{ $t('globals.upload') }}
                <v-icon>mdi-upload</v-icon>
              </v-btn>

              <v-btn
                :key="`doc-key-${doc.name}`"
                class="mr-3 my-2"
                color="white"
                v-for="doc in sectionLocal.resources"
              >
                <span class="text-truncate" style="max-width: 100%">{{ doc.name || '' }}</span>
                <v-icon color="red" :disabled="!canUpdate" @click="removeDocument(doc)">
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-card-text>

        <v-card-actions v-if="type === 'add'" class="actions">
          <v-btn
            data-test-id="cancel"
            color="primary"
            outlined
            @click="$emit('close-dialog', 'section')"
          >
            {{ $t('globals.cancel') }}
          </v-btn>

          <v-btn
            :disabled="!isValidForm"
            data-test-id="add"
            color="primary"
            @click="$emit('submit', sectionLocal)"
          >
            {{ $t('resources.tab.section.add.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

    <upload-files
      :is-multiple="true"
      :dialog="documentDialog"
      @save="uploadDocuments"
      @close="closeDocumentDialog"
    />
  </v-container>
</template>

<script>
import VueEditorImage from '@/components/vue-editor-image/VueEditorImage.vue';
import UploadFiles from '@/components/admin/upload-files/UploadFiles.vue';
import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import VALIDATORS from '@/helpers/forms/validators.helper';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SectionContent',
  components: {
    VueEditorImage,
    UploadFiles,
  },
  data: () => ({
    toggleValue: false,
    documentDialog: false,
    valid: false,
    validForm: {
      summary: false,
      body: false,
    },
    rules: {
      title: [...VALIDATORS.REQUIRED.TITLE, VALIDATORS.LENGTH.MIN(3), VALIDATORS.LENGTH.MAX(255)],
    },
  }),
  props: {
    section: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'edit',
    },
    canUpdate: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    sectionLocal: {
      get() {
        return this.section;
      },
      set(value) {
        this.$emit('section', value);
      },
    },
    isValidForm() {
      return this.valid && this.validForm.body && this.validForm.summary;
    },
  },
  watch: {
    isValidForm: {
      handler() {
        if (this.type === 'edit') {
          this.$emit('set-valid', this.isValidForm);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.toggleValue = !!this.sectionLocal.resources.length;
  },
  methods: {
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    setContent(value, type) {
      this.sectionLocal[type] = value;
    },
    setHasErrorContent(value, type) {
      this.validForm[type] = value;
    },
    async uploadDocuments(documents) {
      const newList = [];
      // eslint-disable-next-line no-restricted-syntax
      for await (const document of documents) {
        await this[UPLOAD_FILE](document);
        newList.push({
          name: document.name,
          url: this.file.url,
        });
      }
      this.sectionLocal.resources = [...this.sectionLocal.resources, ...newList];
      this.closeDocumentDialog();
    },
    removeTag(item) {
      this.sectionLocal.tags = [...this.sectionLocal.tags.filter((tag) => tag !== item)];
    },
    closeDocumentDialog() {
      this.documentDialog = false;
    },
    removeDocument(doc) {
      const newList = this.sectionLocal.resources.filter((ele) => ele.url !== doc.url);
      this.sectionLocal.resources = newList;
    },
  },
};
</script>
