<template>
  <v-container class="kiosk-configuration">
    <h2 class="kiosk-configuration__title">{{ event.name }} - {{ formTitle }}</h2>

    <v-container class="kiosk-configuration__content">
      <v-tabs v-model="exhibitorTab">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab href="#exhibitor">{{ $t('exhibitor.title') }}</v-tab>
        <v-tab href="#rep">{{ $t('rep.title') }}</v-tab>
        <v-tab href="#resources">{{ $t('resources.title') }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="exhibitorTab">
        <v-tab-item value="exhibitor">
          <v-form :disabled="!canUpdateSubmit" v-model="valid">
            <v-row>
              <v-col cols="12">
                <h2>{{ $t('admin.exhibitors.edit.labels.generalInformation') }}</h2>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="exhibitorForm.name"
                  :label="$t('admin.exhibitors.edit.name')"
                  :rules="rules.nameRequired"
                  :disabled="!(canUpdateAdminInfo && !isSubmitted)"
                />
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="exhibitorForm.priority"
                  :items="priorities"
                  :label="$t('admin.exhibitors.edit.priority')"
                  persistent-placeholder
                  placeholder="-"
                  :disabled="!(canUpdateAdminInfo && !isSubmitted)"
                />
              </v-col>

              <v-col cols="12" class="pb-2">
                <div>
                  <h3>{{ $t('kioskBuilder.contacts.title') }}</h3>
                  <div>
                    {{ $t('kioskBuilder.contacts.detail') }}
                  </div>
                </div>

                <kiosk-contacts-step
                  :exhibitor-form="exhibitorForm"
                  @update-address="updateAddress"
                  @update-phone-number="updatePhoneNumber"
                  :canUpdate="canUpdate"
                />
              </v-col>

              <v-col cols="12" class="pb-2">
                <div>
                  <h3>{{ $t('kioskBuilder.socials.title') }}</h3>
                  <div>
                    {{ $t('kioskBuilder.socials.detail') }}
                  </div>
                </div>

                <kiosk-socials-step :exhibitor-form="exhibitorForm" :canUpdate="canUpdate" />
              </v-col>

              <v-col cols="12" class="pb-2">
                <kiosk-documents-step
                  :exhibitor-form="exhibitorForm"
                  :canUpdate="canUpdate"
                  @upload-documents="uploadDocuments"
                  @update-documents="updateDocuments"
                />
              </v-col>

              <v-col cols="12" class="pb-2">
                <kiosk-presentation-step
                  :kiosk-options="exhibitorForm.kioskOptions"
                  :picture-is-sending="isSending"
                  :video-is-sending="videoIsSending"
                  :video-is-processing="videoIsProcessing"
                  :tags="exhibitorTags"
                  :canUpdate="canUpdate"
                  @on-tags-update="onTagsUpdate"
                  @upload-picture="uploadPicture"
                  @upload-video="uploadVideo"
                />
              </v-col>

              <v-col cols="6" sm="12" v-if="isEditing">
                <v-checkbox
                  v-model="exhibitorForm.kioskOptions.isSubmitted"
                  :label="$t('kioskBuilder.edit.submitted')"
                  :disabled="!canUpdateSubmit"
                />
              </v-col>

              <v-col cols="6" sm="12" v-if="isEditing">
                <v-btn color="primary" @click="configureKiosk" :disabled="!canUpdate" depressed>
                  {{ $t('admin.exhibitors.edit.configure') }}
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>

        <v-tab-item value="rep">
          <v-dialog content-class="v-dialog--overlay" v-model="dialog.rep" max-width="80%">
            <template v-slot:activator="{ on, attrs }">
              <div class="kiosk-configuration__rep-btn-link">
                <v-btn
                  color="primary"
                  dark
                  depressed
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!canUpdate"
                >
                  {{ $t('admin.exhibitors.edit.rep.link') }}
                </v-btn>
              </div>
            </template>

            <v-card>
              <v-card-title>
                <span class="headline">{{ $t('admin.exhibitors.edit.rep.link') }}</span>
              </v-card-title>

              <v-card-text>
                <v-autocomplete
                  :filter="() => true"
                  :hide-no-data="true"
                  :items="items"
                  :loading="publicProfilesAreLoading"
                  :placeholder="$t('globals.startSearch')"
                  :search-input.sync="search"
                  @update:search-input="onSearch"
                  auto-select-first
                  autofocus
                  flat
                  hide-details
                  item-text="fullName"
                  item-value="id"
                  v-model="selectedId"
                />
              </v-card-text>

              <v-card-actions class="actions">
                <v-btn color="primary" outlined @click="closeDialog('rep', false)">
                  {{ $t('globals.cancel') }}
                </v-btn>

                <v-btn
                  color="primary"
                  :loading="publicProfilesAreLoading"
                  :disabled="publicProfilesAreLoading"
                  @click="closeDialog('rep', true)"
                >
                  {{ $t('globals.link') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-card-text>
            <base-list
              class="mt-4"
              className="img-first"
              :loading="exhibitorsAreLoading"
              :headers="headers"
              :headerProps="{ sortIcon: null }"
              :items="exhibitorForm.users || []"
              :itemsPerPage="10"
              :footerProps="{
                itemsPerPageText: $t('globals.datatable.itemPerPage'),
                itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
                pageText: $t('globals.datatable.of'),
              }"
              :noDataText="$t('globals.datatable.noData')"
              :noResultsText="$t('globals.datatable.noResult')"
            >
              <template v-slot:[`item.avatar`]="{ item }">
                <div class="container-img">
                  <img v-if="item.avatar" :src="item.avatar" class="container-img__img" />
                  <p v-else>{{ $t('admin.users.noAvatar') }}</p>
                </div>
              </template>

              <template v-slot:[`item.actions`]="{ item }" v-if="!exhibitorIsSaving">
                <v-btn :disabled="!canUpdate" @click="unlinkUser(item)">
                  <v-icon :title="$t('admin.exhibitors.edit.rep.unlink')">mdi-link-off</v-icon>
                </v-btn>
              </template>
            </base-list>
          </v-card-text>
        </v-tab-item>

        <v-tab-item value="resources">
          <v-row>
            <v-col cols="12">
              <h2>{{ $t('resources.tabs') }}</h2>
              <v-row class="d-flex mb-2 mt-2 ga-2" data-test-id="row-add">
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="exhibitorForm.kioskTabs[0].name"
                    clearable
                    hide-details="auto"
                    :label="$t('resources.tab.left')"
                    :placeholder="$t('resources.tab.placeholder')"
                    :disabled="!canUpdate"
                    @click="clearSelect"
                    @click:clear="clearSelect"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="exhibitorForm.kioskTabs[1].name"
                    clearable
                    hide-details="auto"
                    :label="$t('resources.tab.right')"
                    :placeholder="$t('resources.tab.placeholder')"
                    :disabled="!canUpdate"
                    @click="clearSelect"
                    @click:clear="clearSelect"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h2>{{ $t('resources.tab.content') }}</h2>
            </v-col>
          </v-row>

          <v-select
            :label="$t('resources.tab.name')"
            :items="tabsSelect"
            item-value="id"
            item-text="name"
            v-model="selectedTab"
          />

          <div v-if="isSelectedTab" class="d-flex align-center">
            <v-btn
              class="mr-4"
              large
              depressed
              color="primary"
              :disabled="!canUpdate"
              @click="openDialog('section')"
            >
              {{ $t('resources.tab.section.add.title') }}

              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </div>

          <v-container id="wrap-list-section">
            <div v-if="isSelectedTab" class="wrap-list-section">
              <base-list
                class="mt-4 pl-6"
                class-name="exhibitors-sections-table"
                :loading="exhibitorsAreLoading"
                :headerProps="{ sortIcon: null }"
                :headers="sectionHeaders"
                :search="search"
                :items="exhibitorForm.kioskTabs[selectedTab].sections"
                :itemsPerPage="10"
                :footerProps="{
                  itemsPerPageText: $t('globals.datatable.itemPerPage'),
                  itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
                  pageText: $t('globals.datatable.of'),
                }"
                :noDataText="$t('globals.datatable.noData')"
                :noResultsText="$t('globals.datatable.noResult')"
                :expanded="expanded"
                @expand-change="expandedChange"
              >
                <template #body="data">
                  <Draggable
                    tag="tbody"
                    v-model="exhibitorForm.kioskTabs[selectedTab].sections"
                    :disabled="disabledDraggable"
                  >
                    <tr
                      v-for="(item, index) in data.items"
                      :key="`row-${index}`"
                      class="wrap-list-section__item"
                    >
                      <div class="wrap-list-section__index">
                        {{ index }}
                      </div>

                      <template v-for="col in data.headers">
                        <div :key="`col.${index}${col}`">
                          <WrapListSection
                            :index="index"
                            :col="col"
                            :dataItem="data"
                            :item="item"
                            :canUpdate="canUpdate"
                            @delete-section="deleteSection"
                            @set-disabled-draggable="setDisabledDraggable"
                            @set-valid="setValidSection"
                          ></WrapListSection>
                        </div>
                      </template>
                    </tr>
                  </Draggable>
                </template>
              </base-list>
            </div>
          </v-container>
        </v-tab-item>
      </v-tabs-items>

      <v-card-actions v-if="exhibitorTab !== 'rep'" class="actions">
        <v-btn color="primary" outlined @click="goBack" data-test-id="cancel-btn">
          {{ $t('globals.cancel') }}
        </v-btn>

        <v-btn
          color="primary"
          v-if="canUpdateSubmit"
          :loading="exhibitorIsSaving"
          :disabled="exhibitorIsSaving || !valid || !getValidSection() || !canUpdateSubmit"
          @click="save"
          data-test-id="save-btn"
        >
          {{ $t('globals.save') }}
        </v-btn>
      </v-card-actions>
    </v-container>

    <v-dialog
      content-class="v-dialog--overlay kiosk-configuration__dialog"
      v-model="dialog.section"
      v-if="dialog.section"
      width="800"
      @click:outside="closeDialog('section')"
    >
      <section-content
        :section="section"
        @close-dialog="closeDialog"
        @submit="addSection"
        type="add"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Draggable from 'vuedraggable';

import { Exhibitor } from '@/models';

import VALIDATORS from '@/helpers/forms/validators.helper';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import {
  CLEAR_EXHIBITOR,
  GET_EXHIBITOR,
  GET_EXHIBITORS,
} from '@/stores/agnostic/actions/exhibitor/exhibitor.actions';
import {
  PUBLIC_PROFILE_MODULE,
  SEARCH_USER_PROFILES,
} from '@/stores/umanize-app/actions/public-profile/public-profile.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import {
  ADMIN_EXHIBITOR_MODULE,
  LINK_USER,
  SAVE_EXHIBITOR,
  UNLINK_USER,
  UPLOAD_EXHIBITOR_VIDEO,
} from '@/stores/umanize-admin/actions/exhibitor/exhibitor.actions';
import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import { APP_EXHIBITOR_MODULE } from '@/stores/umanize-app/actions/exhibitor/app-exhibitor.actions';
import { ADMIN_TAG_MODULE, GET_TAGS } from '@/stores/umanize-admin/actions/tag/admin-tag.actions';

import BaseList from '@/components/base-list/BaseList.vue';
import KioskContactsStep from '@/components/kiosk-builder/kiosk-contacts-step/KioskContactsStep.vue';
import KioskDocumentsStep from '@/components/kiosk-builder/kiosk-documents-step/KioskDocumentsStep.vue';
import KioskSocialsStep from '@/components/kiosk-builder/kiosk-socials-step/KioskSocialsStep.vue';
import KioskPresentationStep from '@/components/kiosk-builder/kiosk-presentation-step/KioskPresentationStep.vue';
import SectionContent from '@/components/kiosk-builder/kiosk-section-step/wrap-list-section/SectionContent.vue';
import WrapListSection from '@/components/kiosk-builder/kiosk-section-step/wrap-list-section/WrapListSection.vue';

import { ExhibitorSection } from '@/models/exhibitor/exhibitor.section.model';
import { ExhibitorTab } from '@/models/exhibitor/exhibitor.tab.model';

export default {
  name: 'KioskConfiguration',
  components: {
    BaseList,
    WrapListSection,
    Draggable,
    SectionContent,
    KioskContactsStep,
    KioskSocialsStep,
    KioskDocumentsStep,
    KioskPresentationStep,
  },
  props: {
    isEditing: {
      type: Boolean,
      required: true,
    },
    formTitle: {
      type: String,
      required: true,
    },
    configureStandUrl: {
      type: String,
      required: true,
    },
    canUpdateAdminInfo: {
      type: Boolean,
      default: false,
    },
    nameSpaceExhibitor: {
      type: String,
      required: true,
    },
    nameSpaceEvent: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    defaultExhibitor: new Exhibitor(),
    section: new ExhibitorSection(),
    dialog: {
      section: false,
      rep: false,
    },
    unityInstance: undefined,
    videoIsProcessing: false,
    exhibitorForm: new Exhibitor(),
    exhibitorTab: 'exhibitor',
    search: '',
    disabledDraggable: false,
    expanded: [],
    selectedId: null,
    tableItem: [],
    selectedTab: null,
    priorities: [],
    valid: false,
    rules: {
      nameRequired: VALIDATORS.REQUIRED.NAME,
    },
  }),

  computed: {
    ...mapGetters(APP_EXHIBITOR_MODULE, {
      exhibitorSelf: 'exhibitorSelf',
    }),
    ...mapGetters(SHARED_FILE_MODULE, ['file', 'isSending']),
    ...mapGetters(PUBLIC_PROFILE_MODULE, ['publicProfiles', 'publicProfilesAreLoading']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles', 'loggedInUser']),
    ...mapGetters(ADMIN_EXHIBITOR_MODULE, ['video', 'videoIsSending', 'videoIsUploaded']),
    ...mapGetters(ADMIN_TAG_MODULE, ['tagsByType']),

    event() {
      return this.$store.getters[`${this.nameSpaceEvent}/event`];
    },
    exhibitors() {
      return this.$store.getters[`${this.nameSpaceExhibitor}/exhibitors`];
    },
    exhibitor() {
      return this.$store.getters[`${this.nameSpaceExhibitor}/exhibitor`];
    },
    exhibitorIsSaving() {
      return this.$store.getters[`${this.nameSpaceExhibitor}/exhibitorIsSaving`];
    },
    exhibitorsAreLoading() {
      return this.$store.getters[`${this.nameSpaceExhibitor}/exhibitorsAreLoading`];
    },
    exhibitorTags() {
      return this.tagsByType('exhibitor').map((tag) => tag.name);
    },

    isSelectedTab() {
      if (!Number.isInteger(this.selectedTab)) {
        return false;
      }

      return this.exhibitorForm.kioskTabs[this.selectedTab].name;
    },
    sectionHeaders() {
      return [
        {
          text: this.$t('resources.tab.section.title'),
          align: 'start',
          value: 'title',
          width: '100%',
        },
      ];
    },
    headers() {
      return [
        {
          value: 'avatar',
          sortable: false,
        },
        {
          text: this.$t('admin.users.headers.firstName'),
          value: 'firstName',
          sortable: false,
        },
        {
          text: this.$t('admin.users.headers.lastName'),
          value: 'lastName',
        },
        {
          text: this.$t('admin.exhibitors.edit.rep.unlink'),
          value: 'actions',
          sortable: false,
          width: '125px',
          align: 'center',
        },
      ];
    },
    tabsSelect() {
      return this.exhibitorForm.kioskTabs
        .map(({ name }, index) => ({ name, id: index }))
        .filter((tab) => tab?.name);
    },
    items() {
      const filteredList = this.publicProfiles.filter(
        (profile) =>
          this.exhibitorForm &&
          this.exhibitorForm.users &&
          !this.exhibitorForm?.users.some((user) => user.id === profile.id),
      );

      filteredList.forEach((profile, i) => {
        filteredList[i].fullName = `${profile.firstName} ${profile.lastName}`;
      });

      return filteredList;
    },
    isSubmitted() {
      return this.exhibitor?.kioskOptions.isSubmitted;
    },
    canUpdate() {
      return (
        PermissionsUtil.isAuthorized(
          ['permission.exhibitors.canUpdate'],
          this.loggedInUserRoles,
          this.$route.params.eventId,
        ) && !this.exhibitor?.kioskOptions.isSubmitted
      );
    },

    canUpdateSubmit() {
      return (
        this.canUpdate || (this.exhibitor?.kioskOptions.isSubmitted && this.loggedInUser.isAdmin)
      );
    },
  },
  methods: {
    ...mapActions({
      CLEAR_EXHIBITOR(dispatch) {
        return dispatch(`${this.nameSpaceExhibitor}/CLEAR_EXHIBITOR`);
      },
      GET_EXHIBITOR(dispatch, payload) {
        return dispatch(`${this.nameSpaceExhibitor}/GET_EXHIBITOR`, payload);
      },
      GET_EXHIBITORS(dispatch, payload) {
        return dispatch(`${this.nameSpaceExhibitor}/GET_EXHIBITORS`, payload);
      },
    }),

    ...mapActions(ADMIN_EXHIBITOR_MODULE, [
      SAVE_EXHIBITOR,
      LINK_USER,
      UNLINK_USER,
      UPLOAD_EXHIBITOR_VIDEO,
    ]),
    ...mapActions(ADMIN_TAG_MODULE, [GET_TAGS]),
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    ...mapActions(PUBLIC_PROFILE_MODULE, [SEARCH_USER_PROFILES]),

    goBack() {
      this.$router.back();
    },
    updateAddress(address) {
      this.exhibitorForm = {
        ...this.exhibitorForm,
        address,
      };
    },
    updatePhoneNumber(phoneNumber, extension) {
      this.exhibitorForm = {
        ...this.exhibitorForm,
        phoneNumber: extension
          ? `${phoneNumber} ext. ${extension}`
          : phoneNumber.replace(';', ' ext. '),
      };
    },
    updateDocuments(documents) {
      this.exhibitorForm = {
        ...this.exhibitorForm,
        documents: [...documents],
      };
    },
    onTagsUpdate(tags) {
      this.exhibitorForm.kioskOptions.tags = tags.join(',');
    },
    async uploadPicture({ picture, pictureDialogType }) {
      await this[UPLOAD_FILE](picture[0]);
      this.exhibitorForm.kioskOptions[pictureDialogType] = this.file.url;
    },
    async uploadVideo(video) {
      const { eventId } = this.$route.params;
      await this[UPLOAD_EXHIBITOR_VIDEO]({
        eventId,
        video,
        exhibitorId: this.exhibitor.id,
      });
      this.videoIsProcessing = true;
      this.exhibitorForm.kioskOptions.playableMainVideoUrl = this.video.playableUrl;

      setTimeout(() => {
        this.videoIsProcessing = false;
      }, 10000);
    },
    async uploadDocuments(documents) {
      // eslint-disable-next-line no-restricted-syntax
      for await (const document of documents) {
        await this[UPLOAD_FILE](document);
        this.exhibitorForm = {
          ...this.exhibitorForm,
          documents: [
            ...(this.exhibitorForm.documents || []),
            {
              name: document.name,
              url: this.file.url,
            },
          ],
        };
      }
    },
    setSummary(value) {
      this.section.summary = value;
    },
    setBody(value) {
      this.section.body = value;
    },
    clearSelect() {
      this.selectedTab = null;
    },
    expandedChange(params) {
      this.expanded = params;
    },
    setDisabledDraggable(disabled) {
      this.disabledDraggable = disabled;
    },
    setValidSection(valid, index) {
      this.exhibitorForm.kioskTabs[this.selectedTab].sections[index].isValid = valid;
    },
    getValidSection() {
      const sections = [
        ...this.exhibitorForm.kioskTabs[0].sections,
        ...this.exhibitorForm.kioskTabs[1].sections,
      ];

      return sections.every(({ isValid }) => isValid);
    },
    openDialog(name) {
      this.dialog[name] = true;
    },
    deleteSection(index) {
      this.exhibitorForm.kioskTabs[this.selectedTab].sections.splice(index, 1);
      this.closeDialog('section');
    },
    addSection(section) {
      this.exhibitorForm.kioskTabs[this.selectedTab].sections.push({ ...section, isValid: true });
      this.closeDialog('section');
    },
    async save() {
      const { eventId } = this.$route.params;

      await this[SAVE_EXHIBITOR]({
        eventId,
        exhibitor: {
          ...this.exhibitorForm,
          kioskOptions: {
            ...this.exhibitorForm.kioskOptions,
            tags: this.exhibitorForm.kioskOptions.tags.join(','),
          },
        },
      });
      this.goBack();
    },
    async initializeExhibitor(eventId, exhibitorId) {
      await this[GET_EXHIBITOR]({
        eventId,
        exhibitorId,
      });
    },
    initForm() {
      const copyExhibitor = JSON.parse(JSON.stringify(this.exhibitor));

      this.exhibitorForm = {
        ...this.defaultExhibitor,
        ...copyExhibitor,
      };
      this.exhibitorForm.kioskOptions.tags =
        this.exhibitorForm.kioskOptions?.tags?.length > 0
          ? this.exhibitorForm.kioskOptions?.tags.split(',')
          : [];

      if (!this.exhibitorForm.kioskTabs?.length) {
        this.exhibitorForm.kioskTabs = [new ExhibitorTab(), new ExhibitorTab()];
      } else {
        this.exhibitorForm.kioskTabs[0].sections = this.exhibitorForm.kioskTabs[0].sections.map(
          (section) => ({ ...section, isValid: true }),
        );
        this.exhibitorForm.kioskTabs[1].sections = this.exhibitorForm.kioskTabs[1].sections.map(
          (section) => ({ ...section, isValid: true }),
        );
      }
    },
    async linkUser(userId) {
      const { eventId } = this.$route.params;

      await this[LINK_USER]({
        eventId,
        exhibitorId: this.exhibitor.id,
        userId,
      });
      await this.initializeExhibitor(eventId, this.exhibitor.id);
      this.initForm();
    },
    async unlinkUser(user) {
      const { eventId } = this.$route.params;
      await this[UNLINK_USER]({
        eventId,
        exhibitorId: this.exhibitor.id,
        userId: user.id,
      });
      await this.initializeExhibitor(eventId, this.exhibitor.id);
      this.initForm();
    },
    async closeDialog(type, save) {
      this.dialog[type] = false;

      if (type === 'rep') {
        if (save) {
          await this.linkUser(this.selectedId);
        }

        this.selectedId = null;
      }

      if (type === 'section') {
        this.section = new ExhibitorSection();
      }
    },
    async onSearch(searchText) {
      if (
        !this.publicProfilesAreLoading &&
        this.dialog.rep &&
        searchText &&
        searchText.length > 2
      ) {
        const { eventId } = this.$route.params;

        // TODO: Replace by an action in the admin module that matches this call ?
        await this[SEARCH_USER_PROFILES]({
          searchText,
          eventId,
        });
      }
    },
    configureKiosk() {
      this.$router.push({
        name: this.configureStandUrl,
        params: { ...this.$route.params },
      });
    },
  },
  async mounted() {
    const { eventId, exhibitorId } = this.$route.params;

    await this[GET_EXHIBITORS]({ eventId });
    await this[GET_TAGS]({ eventId });

    if ((exhibitorId && this.isEditing) || this.exhibitorSelf?.exhibitorId) {
      await this.initializeExhibitor(eventId, exhibitorId || this.exhibitorSelf?.exhibitorId);
    }

    this.initForm();
    this.exhibitors.forEach((e, idx) => this.priorities.push(idx));
  },
  beforeDestroy() {
    this[CLEAR_EXHIBITOR]();
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();

.kiosk-configuration {
  padding: 50px;
  min-height: calc(100vh - 6rem);

  &__title {
    font-size: $x-large-font-size;
    font-weight: $regular;
    margin-bottom: 10px;
  }

  &__content {
    @include admin-card;

    margin: 0;
    padding: 20px 40px;
  }

  &__rep-btn-link {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 2rem;
  }

  ::v-deep &__dialog.v-dialog--overlay.v-dialog {
    background-color: white !important;

    .v-card {
      box-shadow: none;
    }
  }
}

.container-img {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    font-size: $x-small-font-size;
  }

  &__img {
    width: 100%;
    height: 100%;
  }
}

@include breakpoint(medium) {
  .container-img {
    width: 100px;
    height: 65px;
    border-radius: 10px 0 0 10px;
    border-right: 1px solid var(--v-gray-base);

    img {
      object-fit: cover;
      border-radius: 10px 0 0 10px;
    }
  }
}
</style>
