<template>
  <v-sheet light>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="form.website"
          prepend-icon="mdi-web"
          placeholder="-"
          persistent-placeholder
          hide-details="auto"
          :label="$t('kioskBuilder.edit.website')"
          :rules="rules.url"
          :disabled="!canUpdate"
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="form.email"
          prepend-icon="mdi-at"
          placeholder="-"
          persistent-placeholder
          hide-details="auto"
          :label="$t('kioskBuilder.edit.email')"
          :rules="[rules.email]"
          :disabled="!canUpdate"
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          id="autocomplete"
          v-model="form.address"
          prepend-icon="mdi-map-marker-outline"
          placeholder="-"
          persistent-placeholder
          hide-details="auto"
          :label="$t('kioskBuilder.edit.address')"
          data-test-id="address"
          :disabled="!canUpdate"
        />
      </v-col>

      <v-col cols="9">
        <vue-tel-input-vuetify
          mode="international"
          :value="phoneNumber"
          :label="$t('kioskBuilder.edit.phoneNumber')"
          :maxLen="40"
          :rules="[rules.phone]"
          :disabled="!canUpdate"
          @change="updatePhoneNumber"
        />
      </v-col>

      <v-col cols="3">
        <v-text-field
          hide-details="auto"
          :value="extension"
          :label="$t('kioskBuilder.edit.phoneNumberExtension')"
          :disabled="!canUpdate"
          data-test-id="extension"
          @change="updateExtension"
        />
        <!-- hack to force vuetify select css -->
        <v-select v-show="false" />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import VALIDATORS from '@/helpers/forms/validators.helper';

export default {
  name: 'KioskContactsStep',
  props: {
    exhibitorForm: {
      type: Object,
      required: true,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    autocomplete: null,
    rules: {
      url: [VALIDATORS.URL.FORMAT, VALIDATORS.LENGTH.MAX(256, 'URL')],
      email: VALIDATORS.EMAIL.FORMAT,
      phone: VALIDATORS.PHONE.FORMAT,
    },
  }),
  computed: {
    phoneNumber() {
      return this?.exhibitorForm?.phoneNumber?.split(' ext. ')[0];
    },
    extension() {
      const splittedPhoneNumber = this?.exhibitorForm?.phoneNumber?.split(' ext. ');

      return splittedPhoneNumber?.length > 0 ? splittedPhoneNumber[1] : null;
    },
    form: {
      get() {
        return this.exhibitorForm;
      },
    },
  },
  methods: {
    initAutocomplete() {
      this.autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById('autocomplete'),
        {
          types: ['geocode'],
          componentRestrictions: { country: 'ca' },
        },
      );

      this.autocomplete.setFields(['address_component', 'formatted_address']);
      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace();

        this.$emit('update-address', place.formatted_address);
      });
    },
    updatePhoneNumber(value) {
      const extension = value.includes('ext.') || value.includes(';') ? null : this.extension;
      this.$emit('update-phone-number', value, extension);
    },
    updateExtension(value) {
      this.$emit('update-phone-number', this.phoneNumber, value);
    },
  },
  mounted() {
    this.initAutocomplete();
  },
};
</script>
