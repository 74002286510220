export class ExhibitorSection {
  title: string;

  tags: string[];

  summary: string;

  body: string;

  resources: Document[];

  constructor() {
    this.title = '';
    this.tags = [];
    this.summary = '';
    this.body = '';
    this.resources = [];
  }
}
