<template>
  <v-sheet light>
    <v-col cols="12">
      <v-combobox
        v-if="hasTags"
        :elevation="0"
        v-model="options.tags"
        :items="tags"
        chips
        clearable
        :disabled="!canUpdate"
        :label="$t('admin.tags.title')"
        multiple
      >
        <template v-slot:selection="{ attrs, item, select, selected }">
          <v-chip
            v-bind="attrs"
            :input-value="selected"
            close
            @click="select"
            @click:close="removeTag(item)"
          >
            <strong>{{ item }}</strong>
          </v-chip>
        </template>
      </v-combobox>
    </v-col>

    <v-col cols="12" class="mt-3">
      <div>{{ $t('kioskBuilder.edit.kiosk-builder.logo') }}</div>
      <v-text-field v-model="options.logo" v-show="false" :rules="[rules.url]" />

      <v-img
        class="mt-5 img-container"
        height="110"
        width="200"
        :contain="true"
        :src="options.logo"
      >
        <p v-if="!options.logo">
          {{ $t('kioskBuilder.edit.kiosk-builder.noLogo') }}
        </p>
      </v-img>

      <div class="d-flex align-center">
        <v-btn
          color="primary"
          :disabled="!canUpdate"
          @click="openPictureDialog('logo')"
          data-test-id="upload-logo"
        >
          {{ $t('globals.upload') }}
          <v-icon right>mdi-upload</v-icon>
        </v-btn>

        <div class="mt-3 ml-5 d-flex flex-column picture-details">
          <span>{{ $t('kioskBuilder.edit.kiosk-builder.pictureFormat') }}</span>
          <span>{{ $t('kioskBuilder.edit.kiosk-builder.pictureSize') }} 460 x 255 px</span>
          <span> {{ $t('kioskBuilder.edit.kiosk-builder.pictureResolution') }}</span>
        </div>
      </div>
    </v-col>

    <v-col cols="12">
      <v-switch
        v-model="options.hasMainVideo"
        :disabled="!canUpdate"
        inset
        :label="$t('kioskBuilder.edit.kiosk-builder.hasMainVideo')"
      />
    </v-col>

    <template v-if="options.hasMainVideo">
      <v-col cols="12">
        <div>{{ $t('admin.video.video') }}</div>

        <div v-if="options.playableMainVideoUrl">
          <div class="video-placeholder mt-5" v-if="videoIsProcessing">
            <v-btn
              class="mr-2 mt-5"
              fab
              depressed
              x-small
              :disabled="!canUpdate"
              :loading="videoIsProcessing"
            />
            <span>{{ $t('kioskBuilder.edit.kiosk-builder.videoIsProcessing') }}</span>
          </div>

          <iframe
            class="mt-5"
            v-else
            :src="options.playableMainVideoUrl"
            height="110"
            width="200"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          >
            <p v-if="true">
              {{ $t('kioskBuilder.edit.kiosk-builder.noThumbnail') }}
            </p>
          </iframe>
        </div>

        <v-img v-else class="mt-5 img-container" height="110" width="200" :contain="true">
          <p v-if="!options.playableMainVideoUrl">
            {{ $t('kioskBuilder.edit.kiosk-builder.noVideo') }}
          </p>
        </v-img>

        <v-btn
          class="mt-5"
          color="primary"
          :disabled="isMainVideoEnabled"
          :loading="videoIsSending || pictureIsSending"
          @click="openVideoDialog()"
          data-test-id="upload-video"
        >
          {{ $t('globals.upload') }}
          <v-icon right>mdi-upload</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <div class="mt-3">
          <div>{{ $t('kioskBuilder.edit.kiosk-builder.videoThumbnail') }}</div>
          <v-text-field v-model="options.videoThumbnail" v-show="false" :rules="[rules.url]" />

          <v-img
            class="mt-5 img-container"
            height="110"
            width="200"
            :contain="true"
            :src="kioskOptions.videoThumbnail"
          >
            <p v-if="!options.videoThumbnail">
              {{ $t('kioskBuilder.edit.kiosk-builder.noThumbnail') }}
            </p>
          </v-img>

          <div class="d-flex align-center">
            <v-btn
              color="primary"
              :disabled="!canUpdate"
              @click="openPictureDialog('videoThumbnail')"
              data-test-id="upload-video-thumbnail"
            >
              {{ $t('globals.upload') }}
              <v-icon right>mdi-upload</v-icon>
            </v-btn>

            <div class="mt-3 ml-5 d-flex flex-column picture-details">
              <span>{{ $t('kioskBuilder.edit.kiosk-builder.pictureFormat') }}</span>
              <span
                >{{ $t('kioskBuilder.edit.kiosk-builder.pictureSize') }} {{ this.logo.width }} x
                {{ this.logo.height }} px</span
              >
              <span> {{ $t('kioskBuilder.edit.kiosk-builder.pictureResolution') }}</span>
            </div>
          </div>
        </div>
      </v-col>
    </template>

    <upload-pictures
      :dialog="pictureDialog"
      :is-multiple="false"
      :height="pictureDialogHeight"
      :width="pictureDialogWidth"
      @save="save"
      @close="closePictureDialog"
    />

    <upload-videos
      :dialog="videoDialog"
      :isLoading="videoIsSending"
      @save="uploadVideo"
      @close="closeVideoDialog"
    />
  </v-sheet>
</template>

<script>
import VALIDATORS from '@/helpers/forms/validators.helper';

import UploadPictures from '@/components/admin/upload-pictures/UploadPictures.vue';
import UploadVideos from '@/components/admin/upload-videos/UploadVideos.vue';

export default {
  name: 'KioskPresentationStep',
  components: {
    UploadPictures,
    UploadVideos,
  },
  props: {
    kioskOptions: {
      type: Object,
      required: true,
    },
    pictureIsSending: {
      type: Boolean,
      required: true,
    },
    videoIsSending: {
      type: Boolean,
      required: true,
    },
    videoIsProcessing: {
      type: Boolean,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    rules: {
      url: VALIDATORS.URL.FORMAT,
    },
    videoDialog: false,
    pictureDialog: false,
    pictureDialogHeight: 0,
    pictureDialogWidth: 0,
    pictureDialogConfig: {
      logo: {
        height: 255,
        width: 460,
      },
      videoThumbnail: {
        height: 255,
        width: 460,
      },
    },
    pictureDialogType: null,
  }),
  computed: {
    logo() {
      return this.pictureDialogConfig.logo;
    },
    hasTags() {
      return this.tags.length !== 0;
    },
    isMainVideoEnabled() {
      return this.pictureIsSending || this.videoIsSending || !this.canUpdate;
    },
    options: {
      get() {
        return this.kioskOptions;
      },
    },
  },
  methods: {
    openPictureDialog(pictureType) {
      this.pictureDialogHeight = this.pictureDialogConfig[pictureType].height;
      this.pictureDialogWidth = this.pictureDialogConfig[pictureType].width;
      this.pictureDialog = true;
      this.pictureDialogType = pictureType;
    },
    closePictureDialog() {
      this.pictureDialogType = null;
      this.pictureDialog = false;
    },
    save(picture) {
      this.$emit('upload-picture', {
        picture,
        pictureDialogType: this.pictureDialogType,
      });
      this.closePictureDialog();
    },
    uploadVideo(video) {
      this.$emit('upload-video', video);
      this.closeVideoDialog();
    },
    openVideoDialog() {
      this.videoDialog = true;
    },
    closeVideoDialog() {
      this.videoDialog = false;
    },
    onSurfaceSelect(surface) {
      this.$emit('on-surface-select', surface);
    },
    onFurnitureSelect(furniture) {
      this.$emit('on-furniture-select', furniture);
    },
    removeTag(item) {
      this.options.tags = [...this.kioskOptions.tags.filter((tag) => tag !== item)];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';

img {
  max-height: 200px;
  max-width: 80%;
  border: 1px solid var(--v-gray-base);
}

.picture-details {
  color: var(--v-gray-base);
}

.video-placeholder {
  font-size: $small-font-size;
}

.img-container {
  border: 1px solid var(--v-gray-base);

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
  }
}
</style>
