<template>
  <v-sheet light>
    <v-row>
      <v-col cols="12">
        <h3 class="documents-title pb-2">{{ $t('kioskBuilder.edit.documents.title') }}</h3>
        <base-input-multiple
          :values="exhibitorForm.documents"
          :has-internal-add="false"
          :is-object-array="true"
          @on-change="updateDocuments"
          @add="openDialog"
          :disabled="!canUpdate"
        />
      </v-col>
    </v-row>

    <upload-files :is-multiple="true" :dialog="dialog" @save="saveDocuments" @close="closeDialog" />
  </v-sheet>
</template>

<script>
import BaseInputMultiple from '@/components/base-input-multiple/BaseInputMultiple.vue';
import UploadFiles from '@/components/admin/upload-files/UploadFiles.vue';

export default {
  name: 'KioskDocumentsStep',
  components: {
    UploadFiles,
    BaseInputMultiple,
  },
  props: {
    exhibitorForm: {
      type: Object,
      required: true,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    saveDocuments(documents) {
      this.$emit('upload-documents', documents);
      this.closeDialog();
    },
    updateDocuments(documents) {
      this.$emit('update-documents', documents);
    },
    closeDialog() {
      this.dialog = false;
    },
    openDialog() {
      this.dialog = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../styles/core/variables';
.base-input-multiple {
  padding: $padding-medium;
}
</style>
