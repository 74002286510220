<template>
  <v-sheet light class="stepper__content">
    <v-row>
      <v-col cols="12">
        <v-text-field
          prepend-icon="mdi-twitter"
          placeholder="-"
          persistent-placeholder
          hide-details="auto"
          v-model="form.twitter"
          :label="$t('kioskBuilder.edit.twitter')"
          :rules="[rules.url]"
          :disabled="!canUpdate"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          prepend-icon="mdi-youtube"
          placeholder="-"
          persistent-placeholder
          hide-details="auto"
          v-model="form.youtube"
          :label="$t('kioskBuilder.edit.youtube')"
          :rules="[rules.url]"
          :disabled="!canUpdate"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          prepend-icon="mdi-facebook"
          placeholder="-"
          persistent-placeholder
          hide-details="auto"
          v-model="form.facebook"
          :label="$t('kioskBuilder.edit.facebook')"
          :rules="[rules.url]"
          :disabled="!canUpdate"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          prepend-icon="mdi-instagram"
          placeholder="-"
          persistent-placeholder
          hide-details="auto"
          v-model="form.instagram"
          :label="$t('kioskBuilder.edit.instagram')"
          :rules="[rules.url]"
          :disabled="!canUpdate"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          prepend-icon="mdi-linkedin"
          placeholder="-"
          persistent-placeholder
          hide-details="auto"
          v-model="form.linkedin"
          :label="$t('kioskBuilder.edit.linkedin')"
          :rules="[rules.url]"
          :disabled="!canUpdate"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import VALIDATORS from '@/helpers/forms/validators.helper';

export default {
  name: 'KioskSocialsStep',
  props: {
    exhibitorForm: {
      type: Object,
      required: true,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    rules: {
      url: VALIDATORS.URL.FORMAT,
    },
  }),
  computed: {
    form: {
      get() {
        return this.exhibitorForm;
      },
    },
  },
};
</script>
